.dashboard {
  @apply
  flex
  flex-wrap
}

.dashboardContent {
  @apply
  flex
  flex-col
  flex-1
}

.mobileOnboardingChecklist {
  flex: 0 0 100%;
  @apply
  lg:hidden
}
