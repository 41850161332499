.accountHeading {
  /* font-family: 'Montserrat', sans-serif; */
  @apply
  flex-1
  min-w-0
}
.accountHeadingInner {
  @apply
  flex
  items-center
}

.titleContainerPlusGoodies {
  @apply
  /* border-gray-300
  border */
  py-4
  rounded-md
  /* shadow-md */
}

.titleContainer {
  @apply
  flex
  items-center
}

.title {
  @apply
  text-2xl
  /* font-bold */
  leading-7
  text-gray-900
  sm:leading-9
  sm:truncate
  flex
  relative
}

.titleText {
  @apply
  flex
  flex-wrap
  gap-1
  justify-end
  mr-10
  sm:mr-0
}

.switchIconMobile {
  /* border-radius: 0 50% 50% 0; */
  @apply
  absolute
  -right-3
  top-0
  bottom-0
  w-12
  /* bg-cerise-500 */
  sm:hidden
  flex
  items-center
}

.domainName {
  line-height: 1;
  @apply
  flex
  items-center
  align-middle
  text-white
  sm:text-cerise-400
}

.twitterHandle {
  line-height: 1;
  @apply
  flex
  items-center
  align-middle
  text-white
  sm:text-cerise-400
}

.switchIconDesktop {
  @apply
  mx-4
  hidden
  sm:flex
  items-center
  justify-center
  text-white
  bg-cerise-500
  h-8
  w-8
  rounded-full
}
