.BlogPostMetrics_blogPostMetrics__h2xAB {

}

.BlogPostMetrics_metricsTitle__uNdk_ {

    font-size: 1.125rem;

    font-weight: 500;

    line-height: 1.5rem;

    --tw-text-opacity: 1;

    color: rgb(17 24 39 / var(--tw-text-opacity))
}

.BlogPostMetrics_dateRange__0gr4d {

    margin-left: 0.5rem;

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.BlogPostMetrics_sparkLine__AVGrR {

    margin-bottom: 2rem
}

.Link_link__Moo_U {
    --tw-text-opacity: 1;
    color: rgb(213 48 94 / var(--tw-text-opacity));
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-underline-offset: 1px
}

.NumberIcon_numberIcon__Eqb_J {
    position: relative;
    top: -1px;
    margin-right: 2px;
    display: inline-flex;
    height: 18px;
    width: 18px;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(222 49 101 / var(--tw-border-opacity));
    padding: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(222 49 101 / var(--tw-text-opacity))
}

.Tabs_tabs__v94tF {

}

.InstallationSnippetWidget_installationSnippetWidget__lkkoE {

}

.StatusPillForBlogPost_statusPillForBlogPost__2LZk2 {
    display: inline-flex;
    align-items: center;
    border-radius: 9999px;
    border-width: 1px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase
}

.StatusPillForComment_statusPillForComment__FpnjN {

}

.ListOfContent_listOfContent__UBD6J {

    margin-bottom: 1rem
}

.ListOfContent_header__UVX1M {

    margin-left: auto;

    margin-right: auto;

    display: flex;

    max-width: 80rem;

    align-items: baseline;

    justify-content: flex-start;

    gap: 0.5rem;

    background-image: linear-gradient(to right, var(--tw-gradient-stops));

    --tw-gradient-from: #474a6b;

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(71 74 107 / 0));

    --tw-gradient-to: #33364d;

    padding-left: 1rem;

    padding-right: 1rem;

    padding-bottom: 0.5rem;

    padding-top: 1rem;

    font-size: 1.125rem;

    line-height: 1.5rem;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

@media (min-width: 640px) {

    .ListOfContent_header__UVX1M {

        background-image: none
    }

    .ListOfContent_header__UVX1M {

        padding-left: 1.5rem;

        padding-right: 1.5rem
    }

    .ListOfContent_header__UVX1M {

        --tw-text-opacity: 1;

        color: rgb(0 0 0 / var(--tw-text-opacity))
    }
}

@media (min-width: 1024px) {

    .ListOfContent_header__UVX1M {

        padding-left: 4rem;

        padding-right: 4rem
    }
}

.ListOfContent_engagementItemBase__i4cjm {

    display: flex;

    width: 2.5rem;

    align-items: center;

    justify-content: flex-start;

    padding-top: 0.5rem;

    padding-bottom: 0.5rem;

    font-weight: 500
}

@media (min-width: 640px) {

    .ListOfContent_engagementItemBase__i4cjm {

        width: 3rem
    }
}

.ListOfContent_engagementItem__vXgb9 {

    margin-right: 1rem;

    display: flex;

    width: 2.5rem;

    align-items: center;

    justify-content: flex-start;

    padding-top: 0.5rem;

    padding-bottom: 0.5rem;

    font-weight: 500
}

@media (min-width: 640px) {

    .ListOfContent_engagementItem__vXgb9 {

        width: 3rem
    }
}

.ListOfContent_engagementItemLast__Ao_M4 {

    margin-right: 0px;

    display: flex;

    width: 2.5rem;

    align-items: center;

    justify-content: flex-start;

    padding-top: 0.5rem;

    padding-bottom: 0.5rem;

    font-weight: 500
}

@media (min-width: 640px) {

    .ListOfContent_engagementItemLast__Ao_M4 {

        width: 3rem
    }
}

.ListOfContent_column__kIfxo {

    white-space: nowrap;

    padding-left: 1rem;

    padding-right: 1rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.ListOfContent_columnTitle___RRee {

    width: 100%;

    max-width: 0rem;

    padding-top: 1rem;

    padding-bottom: 1rem;

    white-space: nowrap;

    padding-left: 1rem;

    padding-right: 1rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.ListOfContent_columnStatus__Cfyxk {

    display: none;

    padding-top: 1rem;

    padding-bottom: 1rem;

    white-space: nowrap;

    padding-left: 1rem;

    padding-right: 1rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

@media (min-width: 768px) {

    .ListOfContent_columnStatus__Cfyxk {

        display: block
    }
}

.ListOfContent_columnEngagementItems__Vrxxd {

    padding-top: 0.5rem;

    padding-bottom: 0.5rem;

    text-align: right;

    white-space: nowrap;

    padding-left: 1rem;

    padding-right: 1rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.ListOfContent_columnDate__v__w4 {

    padding-top: 1rem;

    padding-bottom: 1rem;

    text-align: right;

    white-space: nowrap;

    padding-left: 1rem;

    padding-right: 1rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.ListOfContent_engagementItemsRow__D6vOd {

    display: flex
}

.ListOfContent_dateTimeMobile__zF0r3 {

    padding-left: 0.75rem;

    padding-right: 0.75rem;

    padding-top: 0.5rem;

    padding-bottom: 0.5rem
}

.AccountHeading_accountHeading__13I6I {
  /* font-family: 'Montserrat', sans-serif; */
  min-width: 0px;
  flex: 1 1 0%}
.AccountHeading_accountHeadingInner__zOnpV {
  display: flex;
  align-items: center}

.AccountHeading_titleContainerPlusGoodies__o6QB_ {
  border-radius: 0.375rem;
  padding-top: 1rem;
  padding-bottom: 1rem}

.AccountHeading_titleContainer__vxBFA {
  display: flex;
  align-items: center}

.AccountHeading_title__ocjKA {
  position: relative;
  display: flex;
  font-size: 1.5rem;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity))}

@media (min-width: 640px) {
  .AccountHeading_title__ocjKA {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap}
  .AccountHeading_title__ocjKA {
    line-height: 2.25rem}}

.AccountHeading_titleText__uPM8m {
  margin-right: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.25rem}

@media (min-width: 640px) {
  .AccountHeading_titleText__uPM8m {
    margin-right: 0px}}

.AccountHeading_switchIconMobile__Wf7_W {
  /* border-radius: 0 50% 50% 0; */
  position: absolute;
  right: -0.75rem;
  top: 0px;
  bottom: 0px;
  display: flex;
  width: 3rem;
  align-items: center}

@media (min-width: 640px) {
  .AccountHeading_switchIconMobile__Wf7_W {
    display: none}}

.AccountHeading_domainName__ppfqX {
  line-height: 1;
  display: flex;
  align-items: center;
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))}

@media (min-width: 640px) {
  .AccountHeading_domainName__ppfqX {
    --tw-text-opacity: 1;
    color: rgb(222 49 101 / var(--tw-text-opacity))}}

.AccountHeading_twitterHandle__xcpui {
  line-height: 1;
  display: flex;
  align-items: center;
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))}

@media (min-width: 640px) {
  .AccountHeading_twitterHandle__xcpui {
    --tw-text-opacity: 1;
    color: rgb(222 49 101 / var(--tw-text-opacity))}}

.AccountHeading_switchIconDesktop__qRwcC {
  margin-left: 1rem;
  margin-right: 1rem;
  display: none;
  height: 2rem;
  width: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(222 49 101 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))}

@media (min-width: 640px) {
  .AccountHeading_switchIconDesktop__qRwcC {
    display: flex}}

.DashboardView_dashboard__FzkIN {
  display: flex;
  flex-wrap: wrap}

.DashboardView_dashboardContent__HnUlr {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column}

.DashboardView_mobileOnboardingChecklist__wstIg {
  flex: 0 0 100%}

@media (min-width: 1024px) {

  .DashboardView_mobileOnboardingChecklist__wstIg {
    display: none}}

